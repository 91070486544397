@import './globalStyle.scss';

.fillBtn,
.fillBtn:focus {
    background-color: var(--main_purple) !important;
    min-height: 56px !important;
    border-radius: 12px !important;
    color: var(--white);
    // font-size: $f16 !important;
    box-shadow: none !important;
    border: 0px !important;
    width: 100%;
}

.linkBtn,
.linkBtn:focus {
    border: 0px !important;
    background-color: transparent !important;
    font-family: "poppins_medium";
    // font-size: $f16 !important;
    color: #000 !important;
}

.linkCustom {
    text-decoration: underline !important;
    width: 100%;
}

.outlineBtn,
.outlineBtn:focus {
    background-color: transparent !important;
    border: 1px solid var(--main_purple) !important;
    border-radius: 12px !important;
    color: var(--main_purple) !important;
    font-size: $f16 !important;
    font-family: 'poppins_medium' !important;
    min-height: 56px;
}

.outLineDet,
.outLineDet:focus {
    background-color: transparent !important;
    border: 1px solid var(--dark_red) !important;
    border-radius: 12px !important;
    color: var(--dark_red) !important;
    font-size: $f16 !important;
    font-family: 'poppins_medium' !important;
    min-height: 56px;
}

.filterBtn {
    padding: 12px 16px !important;
}

.filterBtnWhite,
.filterBtnWhite:focus {
    padding: 12px 16px !important;
    background-color: var(--white) !important;
    width: fit-content;
    color: var(--main_purple) !important;
    min-height: 56px;
}

.BtnWhite {
    padding: 12px 16px;
    background-color: var(--white) !important;
    color: var(--main_purple) !important;
    border: 1px solid var(--main_purple);
    min-height: 56px;
}

.customFill,.customFill:focus,.customFill:hover{
    min-height: 52px !important;
    width: 20%;
}
// upload div of buttons and input
.file_div_import {
    position: relative;
    // min-height: 56px;
    
    input {
        // min-height: 56px;
        overflow: hidden;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: -webkit-fill-available;
    }

}