@import './globalStyle.scss';

.main_paginate {
    display: flex;
    justify-items: center;
    justify-content: center;
    list-style: none;

    .page_style {
        font-family: "poppins_regular";
        font-size: 14px;
        min-width: 32px;
        height: 32px;
        border-radius: 50px;
        border: 1px solid #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;

    }

    .page_active_style {
        background-color: var(--main_purple);
        color: #fff;
        border: 0px;
    }

    .arrow_style {
        min-width: 32px;
        height: 32px;
        border-radius: 50px;
        border: 1px solid #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;

        a {
            text-decoration: none;
            color: var(---light_dark);
        }
    }
}