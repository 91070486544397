@import "./globalStyle.scss";

.tableStyle {
  overflow-x: auto;
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-style: none;
  }

  tr {
    height: 64px;
    vertical-align: middle;
    white-space: nowrap;
  }

  thead th {
    color: var(--main_purple);
    font-size: $f16;
    font-family: "poppins_medium";
  }

  tbody td {
    color: #121212;
    font-size: $f16;
  }
}

.tableStyle > tbody > tr:nth-of-type(odd) > * {
  --bs-table-bg-type: #f9f9f9 !important;
}

// section of tables inscreens
.table_section {
  width: 100%;
  padding-bottom: 10px;

  .body_tr_style {
    td {
      font-size: $f16;
      color: var(--light_black);
    }

    .td_bold_link {
      font-family: "poppins_medium";
      a {
        color: var(--light_black) !important;
        text-decoration: none !important;
      }
    }
  }
}

.badge {
  font-size: $f14;
  width: 90px;
  min-height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 12px;
  margin-bottom: 0px;
}

.badgeYellow {
  background-color: var(--main_yellow);
  color: var(--yellow_color_dark);
}

.badgeGreen {
  background-color: var(--background_green);
  color: var(--green);
}

.badgeRed {
  background-color: var(--background_red);
  color: var(--dark_red);
}
.flag {
  font-size: $f16;
  font-family: "poppins_bold";
}

.menuTable {
  tr > th,
  td {
    &:nth-child(2) {
      width: 70%;
      text-align: center;
    }
  }
}

.categoryTable {
  tr > th,
  td {
    &:nth-child(2),
    &:nth-child(3) {
      width: 30%;
      text-align: center;
    }
  }
}

.flexTabelContainer {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
