@import './globalStyle.scss';

.app__sidebar{
    background-color: var(--main_background);
    box-shadow: 0px 4px 12px 0px rgba(206, 206, 206, 0.25);
    transition: all .2s ease-in-out;
}
.main_menu {
    // position: sticky;
    // top: 0px;
    // left: 0px;
    height: 100vh;
    width: 240px;
    margin: 0px;
    // background-color: var(--main_background);
    padding-top: 24px;
    // box-shadow: 0px 4px 12px 0px rgba(206, 206, 206, 0.25);
    transition: all .2s ease-in-out;

    .logo {
        margin-bottom: 50px;
    }


    .navLinkStyle {
        width: 100%;
        font-size: $f16;
        font-family: 'poppins_medium';
        height: 52px;
        margin-bottom: 36px;
        text-decoration: none;
        padding-left: 16px;

        .img_div {
            margin-right: 10px;

        }
    }


    .activeNav {
        background-color: var(--main_purple) !important;
        color: #fff !important;
        display: flex;
        align-items: center;
        border-radius: 12px;

        .img_div {
            background-color: #fff;
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
        }
    }

    .navStyle {
        background-color: transparent;
        color: var(--gray1) !important;
    }
}

// header style
.main_header {
    // position: sticky;
    // top: 0px;
    // left: 0px;
    height: 88px;
    background-color: var(--main_background);
    width: 100%;
    padding: 0px 20px;

    .flex_space {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            font-size: $f24;
            font-family: "poppins_bold";
        }

        .alert_div {
            position: relative;
            width: 48px;
            height: 48px;
            border-radius: 12px;
            border: 1px solid #F5F5F5;
            margin-right: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            .activePoint {
                position: absolute;
                top: 0;
                right: 0;
                background-color: var(--dark_red);
                width: 12px;
                height: 12px;
                border-radius: 50px;

            }
        }

        .dropdown_class {
            background-color: transparent;
            border-radius: 12px;
            border: 1px solid #F5F5F5;
            height: 48px;
            display: flex;
            align-items: center;
            padding: 0px 16px;

            img {
                width: 32px;
                height: 32px;
            }

            p {
                font-size: $f14;
                color: var(--gray2);
                font-family: "poppins_medium";
                margin-bottom: 0px;
                padding: 0px 6px;
            }

            &::after {
                color: var(--gray2);
            }

        }
    }

    .flex_center {
        display: flex;
        align-items: center;
    }

}