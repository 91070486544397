:root {
  /* new */
  --main_purple: #010066;
  --white: #ffffff;
  --main_yellow: #e9e338;
  --main_gray: #f2f2f4;
  --gray1: #bababa;
  --dark_red: #d73d38;
  --background_red: rgba(227, 51, 45, 0.12);
  --gray2: #292d32;
  --green: #1a9946;
  --background_green: rgba(26, 153, 70, 0.12);
  --main_background: #fff;
  --light_black: #121212;
  --dark_white: #f9f9f9;
  /* old */
  --light_green: #41b6a8;
  --light_orange: #f7a46b;
  --navy_color: #072040;
  --yellow_color_dark: #ff5000;
  --yellow_color: #ffc709;
  --toastify-text-color-error: #000;
}

@font-face {
  font-family: "poppins_light";
  src: url(../assets/fonts/Poppins-Light.ttf);
}

@font-face {
  font-family: "poppins_bold";
  src: url(../assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: "poppins_regular";
  src: url(../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "poppins_medium";
  src: url(../assets/fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: "lilita_one";
  src: url(../assets/landing_fonts/LilitaOne-Regular.ttf);
  font-weight: 400;
}

// notes for weigth
/*
font-weight: 100;
font-weight: 200;
font-weight: 300;
font-weight: 400; // normal 
font-weight: 500;
font-weight: 600;
font-weight: 700; // bold 
font-weight: 800;
font-weight: 900;
*/
body {
  margin: 0;
  font-family: "poppins_regular", "poppins_light", "poppins_bold",
    "poppins_medium", "lilita_one";
  /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */

  background-color: var(--main_gray);
}

// declare all variables that will use it
$f32: 2rem;
$f30: 1.875rem;
$f28: 1.75rem;
$f26: 1.625rem;
$f24: 1.5rem;
$f22: 1.375rem;
$f20: 1.25rem;
$f18: 1.125rem;
$f16: 1rem;
$f14: 0.875rem;
$f12: 0.75rem;
$f10: 0.625rem;

.main_layout {
  display: flex;

  .main_page_layout {
    display: block;
    width: 100%;
  }
}

// chart

.echarts-for-react > div,
.echarts-for-react canvas {
  width: 100% !important;
}
// for style tle main routerComponent
.app {
  display: flex;
  min-height: 100vh;

  &__content {
    flex: 1;
    padding: 32px 40px;
    width: 80%;
    @media (max-width: 992px) {
      padding-right: 0px;
      padding-left: 10px;
    }
  }
}

.white_sec {
  background-color: var(--main_background);
  margin-bottom: 2rem;
  border-radius: 12px;
}

.section_head_filter {
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  h4 {
    font-size: $f24;
    color: var(--main_purple);
    font-family: "poppins_bold";
    margin-bottom: 0px;
  }
}

// range date
.date_range {
  .rdrDateDisplayWrapper {
    min-width: 323px;
    border-radius: 12px;
    background-color: var(--main_background);
    border: 1px solid #f5f5f5;
  }

  .rdrMonthAndYearWrapper {
    display: none !important;
  }

  .rdrMonthsVertical {
    display: none !important;
  }

  .rdrDateDisplayItemActive {
    border: 0px;
  }
}
.rdrCalendarWrapper {
  background: transparent !important;
}

.date_range_focused {
  background: #fff !important;
  position: absolute;
  top: -22px;
  right: 10px;
  background-color: var(--main_background);
  z-index: 1;
  box-shadow: 0px 4px 12px 0px rgba(206, 206, 206, 0.25);
  transition: all 0.2s ease-in-out;

  .rdrDateDisplayWrapper {
    // width: 237px;
    border-radius: 12px;
    background-color: var(--main_background);
    border: 1px solid #f5f5f5;
  }

  .rdrMonthAndYearWrapper,
  .rdrMonthsVertical {
    display: flex !important;
    // width: 237px !important;
  }

  .rdrDateDisplayItemActive {
    border-color: var(--main_purple);
  }

  .rdrStartEdge,
  .rdrEndEdge {
    color: var(--main_purple) !important;
  }
}

.date_range .rdrDateDisplayItem,
.date_range_focused .rdrDateDisplayItem {
  box-shadow: none !important;

  input {
    color: var(--main_purple);
    font-size: $f14;
    font-family: "poppins_regular";
  }
}

.date_range .rdrDateDisplayItem + .rdrDateDisplayItem,
.date_range_focused .rdrDateDisplayItem + .rdrDateDisplayItem {
  margin: 0px !important;
}

.date_range_focused + .calenderImg_style {
  display: none;
}

.date_range_close {
  display: none;
}

.date_range_close_focused {
  border: none;
  border-radius: 5px;
  display: block;
  position: absolute;
  top: -70px;
  padding-block: 0;
  right: 10px;
  height: 30px !important;
  width: fit-content !important;
}

// checkbox change color
.form-check-input:checked {
  background-color: var(--main_purple) !important;
  border-color: var(--main_purple) !important;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: var(--main_purple) !important;
}

.img_sm_round {
  width: 56px;
  height: 56px;
}

// start ..landing page style
// swipper

.swiperSty.swiper-autoheight,
.swiperSty.swiper-autoheight .swiper-slide {
  height: 600px;
}

.swiperSty .swiper-button-prev,
.swiperSty .swiper-button-next {
  bottom: 0px;
  top: unset;
  background: var(--main_purple);
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.swiperSty .swiper-button-prev {
  left: 45%;
  transform: translateX(-50%);
}

.swiperSty .swiper-button-next {
  right: 45%;
  transform: translateX(-50%);
}

.swiperSty .swiper-button-next:after,
.swiperSty .swiper-rtl .swiper-button-prev:after,
.swiperSty .swiper-button-prev:after,
.swiperSty .swiper-rtl .swiper-button-next:after {
  font-size: 20px;
  color: #fff;
}

.swiperSty .swiper-button-prev.swiper-button-disabled,
.swiperSty .swiper-button-next.swiper-button-disabled {
  background-color: #b0afab;
}

// tags component
.overlay_div {
  border: 1px solid #d9d9d9;
  border-radius: 10px;

  hr {
    margin: 0.1rem 0px !important;
  }

  p {
    font-size: $f16;
    padding: 6px 10px;
    margin-bottom: 0px;
    cursor: pointer;

    &:first-child {
      color: var(--light_black);
    }

    &:last-child {
      color: var(--dark_red);
    }
  }
}

// .AsyncPaginateStyle > div{
//     min-height: 52px;
//     border-radius: 12px;
//     .multiValue{
//         background-color: #f7a46b;
//         color: #fff;
//         border-radius: 6px;
//     }
// }

// landing modal
.modal_landing_style {
  h6 {
    color: var(--main_purple);
    font-size: 1rem;
    font-family: "poppins_medium";
  }

  p {
    color: var(--light_black);
    font-size: 0.875rem;
    font-family: "poppins_medium";
  }
}
@media (max-width: 768px) {
  .landingNavbar {
    .nav-link {
      font-family: "poppins_medium";
      font-size: $f18;
      color: #000;
      padding: 8px 5px !important;
      margin-bottom: 10px;
    }
  }

  .swiperSty .swiper-button-prev {
    left: 38% !important;
  }

  .swiperSty .swiper-button-next {
    right: 30% !important;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .swiperSty .swiper-button-prev {
    left: 45% !important;
  }

  .swiperSty .swiper-button-next {
    right: 40% !important;
  }
}

// end ..landing page style
