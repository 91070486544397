@import './globalStyle.scss';

.formGroupStyle {
    margin-bottom: 1.5rem;
}

.labelStyle {
    font-size: $f16;
}

.inputStyle,
.inputStyle:focus {
    height: 52px;
    border-radius: 12px !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.inputStyle:focus {
    border-color: var(--main_purple) !important;
}

.iconAbsol {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
}

.searchInputStyle {
    background-color: var(--main_background);
    border-radius: 12px !important;
    border: 0px !important;
    color: var(--main_purple) !important;
    font-size: $f16 !important;
    height: 56px;
    padding-left: 50px !important;
}

.searchInputStyle::-webkit-input-placeholder,
.whiteInput::-webkit-input-placeholder {
    color: var(--main_purple) !important;
}


.whiteInput {
    background-color: var(--main_background);
    border-radius: 12px !important;
    border: 0px !important;
    color: var(--main_purple) !important;
    font-size: $f16 !important;
    height: 56px;
}

.customInput {
    background-color: var(--white);
    height: 52px;
    border-radius: 12px !important;
    box-shadow: none;
}

.searchIcon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-55%);
}


// dates picker
.calenderImg {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-55%);
}

.upload_profile_class {
    position: relative;
    width: 200px;
    height: 200px;
    float: right;

    .upload_profile_input,
    .upload_profile_input:disabled,
    .upload_profile_input[readonly] {
        overflow: hidden;
        opacity: 0;
        width: 200px;
        height: 200px;
        border-radius: 50px;
        position: absolute;
        top: 0px;
        left: 10px;
        // z-index: -1;
    }

    .upload_profile_btn {

        width: 200px;
        height: 200px;
        // background: #F8F8FF;
        border: 1px dashed var(--main_yellow);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 10px;

        img {
            width: -webkit-fill-available;
            border-radius: 50%;
            width: 200px;
            height: 200px;

        }
    }

    .editImgIcon {
        position: absolute;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        left: 55%;
        bottom: -14px;
        transform: translateX(-55%);
        background-color: var(--main_purple);
        color: var(--main_yellow);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}