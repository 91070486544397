@import './globalStyle.scss';

.main_container {
    margin: 0px 24px;

    .login_div1 {
        @media (min-width: 768px) {
            padding: 127px 50px 50px 40px;
        }

        .title_div {
            margin: 75px 0px 50px 0px;

            h2 {
                font-size: $f30;
                color: var(--main_purple);
                font-family: "poppins_medium";
            }

            h6 {
                font-size: $f20;
                color: var(--main_purple);
                font-family: "poppins_light";
            }
        }
    }

    .login_div2 {
        padding: 24px 0px;
        position: relative;

        .sliderImg {
            border-radius: 60px 0px 60px 0px;
            height: auto;
            width: 100%;
        }

        .imgOverlay {
            background: linear-gradient(0deg, rgba(18, 18, 18, 0.72) 0%, rgba(18, 18, 18, 0.00) 100%);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 60px 0px 60px 0px;
        }

        .carouselArrow {
            position: absolute;
            bottom: 10%;
            right: 10%
        }

        .arrowIcon {
            width: 25px;
            height: 25px;
            color: #fff;
            cursor: pointer;
        }
    }
}